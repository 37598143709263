import classNames from 'classnames';
import PropTypes, { func } from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import arrowLeftSVG from '../../assets/arrow_left.svg';
import arrowRightSVG from '../../assets/arrow_right.svg';
import editAvailabilityIcon from '../../assets/custom_edit.svg';
import css from './CustomAvailability.module.css';
import closeSVG from '../../assets/custom_close.svg';

const getWeekDates = date => {
  const now = new Date(date);
  const currentDay = now.getDay(); // 0 is Sunday, 1 is Monday, etc.

  // Calculate days to subtract to get to Monday (start of week)
  const daysToMonday = currentDay === 0 ? 6 : currentDay - 1;

  // Create start date (Monday)
  const startDate = new Date(now);
  startDate.setDate(now.getDate() - daysToMonday);
  startDate.setHours(0, 0, 0, 0);

  // Create end date (Sunday)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  endDate.setHours(23, 59, 59, 999);

  return {
    startDate,
    endDate,
  };
};
const CustomAvailability = ({ availabilityData, exceptions, form, openModal }) => {
  const [currentWeek, setCurrentWeek] = useState(getWeekDates(new Date()));
  const nextWeek = () =>
    setCurrentWeek(
      getWeekDates(new Date(currentWeek.startDate).getTime() + 7 * 24 * 60 * 60 * 1000)
    );
  const previousWeek = () =>
    setCurrentWeek(
      getWeekDates(new Date(currentWeek.startDate).getTime() - 7 * 24 * 60 * 60 * 1000)
    );
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const isPreviousWeekDisabled = () => {
    const firstWeekStart = getWeekDates(new Date()).startDate;
    const currentWeekStart = new Date(currentWeek.startDate);
    return currentWeekStart <= firstWeekStart;
  };

  const removeAvailability = date => {
    if (form) {
      form.change('exceptions', exceptions.filter(d => d !== date));
    }
  };

  return (
    <>
      <div className={css.weekNavigation}>
        <div className={css.weekLabel}>
          <div className={css.displayFlex}>
            <div>
              <span>
                <FormattedMessage id="CustomAvailability.weekOf" />
              </span>
              <span className={css.dateRange}>
                {currentWeek.startDate.toLocaleDateString('en-GB')} -{' '}
                {currentWeek.endDate.toLocaleDateString('en-GB')}
              </span>
            </div>
            <button
              type="button"
              onClick={() => previousWeek()}
              disabled={isPreviousWeekDisabled()}
              className={css.navigationButton}
            >
              <img src={arrowLeftSVG} />
            </button>
            <button type="button" onClick={() => nextWeek()} className={css.navigationButton}>
              <img src={arrowRightSVG} />
            </button>
          </div>
          {form ? (
            <button className={css.editSchedule} type="button" onClick={openModal}>
              <img
                className={css.editAvailabilityIcon}
                src={editAvailabilityIcon}
                alt="edit schedule"
              />{' '}
              <FormattedMessage id="ProfileLocalizationForm.addAvailability" />
            </button>
          ) : null}
        </div>
      </div>
      <div
        className={css.availabilityContainer}
        style={{ display: 'grid', gridTemplateColumns: '1fr' }}
      >
        {days.map((day, index) => {
          const thisDayDate = new Date(currentWeek.startDate);
          thisDayDate.setDate(currentWeek.startDate.getDate() + index);
          const isDayAvailable = exceptions.includes(new Date(thisDayDate).toDateString());

          return (
            <React.Fragment key={index}>
              <div
                className={classNames(css.dayContainer, {
                  [css.availableContainer]: isDayAvailable === true,
                  [css.notAvailableContainer]: isDayAvailable === false,
                })}
              >
                <div className={css.statusContainer}>
                  <div>
                    <div className={css.dayHeader}>
                      {day.charAt(0).toUpperCase() + day.slice(1)} -{' '}
                      {thisDayDate.toLocaleDateString('en-GB')}
                    </div>
                    <div
                      className={
                        isDayAvailable ? css.availabilityStatus : css.notAvailabilityStatus
                      }
                    >
                      {isDayAvailable ? 'Available' : 'Unavailable'}
                    </div>
                  </div>
                  {form && isDayAvailable ? (
                    <button
                      type="button"
                      className={css.deleteButton}
                      onClick={() => removeAvailability(new Date(thisDayDate).toDateString())}
                      aria-label="Delete availability"
                    >
                      <img src={closeSVG} width={24} />
                    </button>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

CustomAvailability.propTypes = {
  availabilityData: PropTypes.object.isRequired,
  openModal: func,
};

export default CustomAvailability;
